import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
import axios from "axios";

const initialState = {
  loading: false,
  loadingMore: false,
  createLoading: false,
  error: false,
  data: [],
  singleOrder: {},
  editingOrder: {},
  years: [],
  orderCreated: false,
  csvImported: false,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetOrderCreated(state, action) {
      state.orderCreated = false;
    },
    resetEditingOrder(state, action) {
      state.editingOrder = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addMoreOrders.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreOrders.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = {
          ...state.data,
          orders: [...state.data.orders, ...action.payload.orders],
          totalCount: action.payload.totalCount,
          page: action.payload.page,
        };
      })
      .addCase(addMoreOrders.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      })
      .addCase(getOrdersByRetailerCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrdersByRetailerCode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getOrdersByRetailerCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addMoreOrdersByRetailerCode.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreOrdersByRetailerCode.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = {
          ...state.data,
          orders: [...state.data.orders, ...action.payload.orders],
          totalCount: action.payload.totalCount,
          page: action.payload.page,
        };
      })
      .addCase(addMoreOrdersByRetailerCode.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      })
      .addCase(getOrdersByAgentCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrdersByAgentCode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getOrdersByAgentCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addMoreOrdersByAgentCode.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreOrdersByAgentCode.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = {
          ...state.data,
          orders: [...state.data.orders, ...action.payload.orders],
          totalCount: action.payload.totalCount,
          page: action.payload.page,
        };
      })
      .addCase(addMoreOrdersByAgentCode.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      })
      .addCase(getOrderById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleOrder = action.payload;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEditingOrderById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEditingOrderById.fulfilled, (state, action) => {
        state.loading = false;
        state.editingOrder = action.payload;
      })
      .addCase(getEditingOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateOrderStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {
          ...state.data,
          orders: state.data.orders?.map((order) => {
            if (order.internalCode === action.payload.internalCode) {
              return action.payload;
            }
            return order;
          }),
        };
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateOrderById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrderById.fulfilled, (state, action) => {
        state.loading = false;
        state.orderCreated = true;
        state.data = {
          ...state.data,
          orders: state.data.orders?.map((order) => {
            if (order.internalCode === action.payload.internalCode) {
              return action.payload;
            }
            return order;
          }),
        };
      })
      .addCase(updateOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteOrderById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteOrderById.fulfilled, (state, action) => {
        state.loading = false;
        // filter out the deleted order
        state.data = {
          orders: state.data.orders.filter(
            (order) => order.internalCode != action.payload
          ),
          totalCount: state.data.totalCount - 1,
          page: state.data.page,
        };
      })
      .addCase(deleteOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(duplicateOrderById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(duplicateOrderById.fulfilled, (state, action) => {
        state.loading = false;
        // add the duplicated order to the list
        state.data = {
          ...state.data,
          orders: [action.payload, ...state.data.orders],
          totalCount: state.data.totalCount + 1,
        };
      })
      .addCase(duplicateOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getOrderYears.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrderYears.fulfilled, (state, action) => {
        state.loading = false;
        state.years = action.payload;
      })
      .addCase(getOrderYears.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.years = [];
      })
      .addCase(importOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(importOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.csvImported = action.payload;
      })
      .addCase(importOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.csvImported = false;
      })
      .addCase(createNewOrder.pending, (state, action) => {
        state.loading = true;
        state.createLoading = true;
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.createLoading = false;
        state.orderCreated = true;
      })
      .addCase(createNewOrder.rejected, (state, action) => {
        state.loading = false;
        state.createLoading = false;
        state.error = action.payload;
      })
      .addCase(rejectOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(rejectOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {
          ...state.data,
          orders: state.data.orders?.map((order) => {
            if (order.internalCode === action.payload.internalCode) {
              return action.payload;
            }
            return order;
          }),
        };
        state.singleOrder = {
          ...state.singleOrder,
          status: action.payload.status,
        };
      })
      .addCase(rejectOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllOrders = createAsyncThunk(
  "ordersSlice/getAllOrders",
  async ({ currentPage, pageSize, q, orderStatus, typeStatus, date }) => {
    try {
      const { url, method, headers } = api.getAllOrders(
        currentPage,
        pageSize,
        q,
        orderStatus,
        typeStatus,
        date
      );
      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getOrdersByRetailerCode = createAsyncThunk(
  "ordersSlice/getOrdersByRetailerCode",
  async ({
    retailerCode,
    currentPage,
    pageSize,
    q,
    orderStatus,
    typeStatus,
    date,
  }) => {
    try {
      const { url, method, headers } = api.getOrdersByRetailerCode(
        retailerCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        typeStatus,
        date
      );

      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getOrdersByAgentCode = createAsyncThunk(
  "ordersSlice/getOrdersByAgentCode",
  async ({
    agentCode,
    currentPage,
    pageSize,
    q,
    orderStatus,
    typeStatus,
    date,
  }) => {
    try {
      const { url, method, headers } = api.getOrdersByAgentCode(
        agentCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        typeStatus,
        date
      );

      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const addMoreOrders = createAsyncThunk(
  "ordersSlice/addMoreOrders",
  async ({ currentPage, pageSize, q, orderStatus, typeStatus, date }) => {
    try {
      const { url, method, headers } = api.getAllOrders(
        currentPage,
        pageSize,
        q,
        orderStatus,
        typeStatus,
        date
      );
      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const addMoreOrdersByRetailerCode = createAsyncThunk(
  "ordersSlice/addMoreOrdersByRetailerCode",
  async ({
    retailerCode,
    currentPage,
    pageSize,
    q,
    orderStatus,
    typeStatus,
    date,
  }) => {
    try {
      const { url, method, headers } = api.getOrdersByRetailerCode(
        retailerCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        typeStatus,
        date
      );

      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const addMoreOrdersByAgentCode = createAsyncThunk(
  "ordersSlice/addMoreOrdersByAgentCode",
  async ({
    agentCode,
    currentPage,
    pageSize,
    q,
    orderStatus,
    typeStatus,
    date,
  }) => {
    try {
      const { url, method, headers } = api.getOrdersByAgentCode(
        agentCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        typeStatus,
        date
      );

      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getOrderById = createAsyncThunk(
  "ordersSlice/getOrderById",
  async (id) => {
    try {
      const { url, method, headers } = api.getOrderById(id);
      const order = await axios({
        url,
        method,
        headers,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getEditingOrderById = createAsyncThunk(
  "ordersSlice/getEditingOrderById",
  async (id) => {
    try {
      const { url, method, headers } = api.getOrderById(id);
      const order = await axios({
        url,
        method,
        headers,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getOrderYears = createAsyncThunk(
  "ordersSlice/getOrderYears",
  async () => {
    try {
      const { url, method, headers } = api.getOrderYears();
      const order = await axios({
        url,
        method,
        headers,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const importOrders = createAsyncThunk(
  "ordersSlice/importOrders",
  async (file) => {
    try {
      const { url, method, headers, data } = api.importOrders(file);
      const newOrder = await axios({
        url,
        method,
        headers,
        data,
      });
      return newOrder.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const createNewOrder = createAsyncThunk(
  "ordersSlice/createNewOrder",
  async ({ order, hubspotId }) => {
    try {
      const { url, method, headers, data } = api.createNewOrder(
        order,
        hubspotId
      );
      const newOrder = await axios({
        url,
        method,
        headers,
        data,
      });
      return newOrder.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  "ordersSlice/updateOrderStatus",
  async ({ id, status }) => {
    try {
      const { url, method, headers, data } = api.updateOrderStatus(id, status);
      const order = await axios({
        url,
        method,
        headers,
        data,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const rejectOrder = createAsyncThunk(
  "ordersSlice/rejectOrder",
  async (id) => {
    try {
      const { url, method, headers } = api.rejectOrder(id);
      const order = await axios({
        url,
        method,
        headers,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const updateOrderById = createAsyncThunk(
  "ordersSlice/updateOrderById",
  async ({ updateData, hubspotId }) => {
    try {
      const { url, method, headers, data } = api.updateOrderById(
        updateData,
        hubspotId
      );
      const order = await axios({
        url,
        method,
        headers,
        data,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const duplicateOrderById = createAsyncThunk(
  "ordersSlice/duplicateOrderById",
  async (id) => {
    try {
      const { url, method, headers } = api.duplicateOrderById(id);
      const order = await axios({
        url,
        method,
        headers,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const deleteOrderById = createAsyncThunk(
  "ordersSlice/deleteOrderById",
  async (id) => {
    try {
      const { url, method, headers } = api.deleteOrderById(id);
      const order = await axios({
        url,
        method,
        headers,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { resetOrderCreated, resetEditingOrder } = ordersSlice.actions;
export const { actions } = ordersSlice;
export default ordersSlice.reducer;
