import React, { useEffect } from "react";
// import logo from "./logo.svg";
import "./styles/general.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Spinner } from "./shared/Spinner";
import { Helmet } from "react-helmet";

const loading = (
  <div
    className="pt-3 text-center"
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layouts/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/login/Login"));
const Page404 = React.lazy(() => import("./views/page404/Page404"));

const App = () => {
  /*   useEffect(() => {
    HubSpot("26048385", {});
  }, []); */

  return (
    <BrowserRouter>
      <Helmet>
        <title>Catellani & Smith - Gestionale</title>
      </Helmet>
      <React.Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route path="/*" element={<DefaultLayout />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
