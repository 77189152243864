import { baseUrl } from "../config/server";
const API_ROOT_URL = baseUrl;

const getHeaders = (language) => {
  const headers = {
    "Content-Type": "application/json",
    "app-id": "62cee8dc4e4a3d55c1784e47",
    "access-control-allow-origin": "*",
    //     "Accept-Language": lan,
  };
  return headers;
};

const getAuthHeaders = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    ...getHeaders(),
    Authorization: "Bearer " + authToken,
  };
  return headers;
};

const requests = {
  // SESSIONS

  login: (credentials) => {
    return {
      url: `${API_ROOT_URL}/login`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },

  getProfile: () => {
    return {
      url: `${API_ROOT_URL}/profile`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getAllUsers: () => {
    return {
      url: `${API_ROOT_URL}/users`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getPaginatedUsers: (page, limit, searchQuery) => {
    const queryParams = [];
    if (searchQuery) {
      queryParams.push(`q=${searchQuery}`);
    }

    return {
      url: `${API_ROOT_URL}/users/paginated?page=${page}&limit=${limit}${
        queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
      }`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getUsersByAgentCode: (agentCode) => {
    return {
      url: `${API_ROOT_URL}/users/agent/${agentCode}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getUserByUserCode: (userCode) => {
    return {
      url: `${API_ROOT_URL}/users/data/${userCode}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  updateUser: (id, updateData) => {
    return {
      url: `${API_ROOT_URL}/users/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  activateUser: (id, updateData) => {
    return {
      url: `${API_ROOT_URL}/users/activate/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },

  changePassword: (id, updateData) => {
    return {
      url: `${API_ROOT_URL}/users/change-password/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },

  // BRANCHES

  getBranchesByUserId: (userCode) => {
    return {
      url: `${API_ROOT_URL}/branches/user/${userCode}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  createNewBranch: (data) => {
    return {
      url: `${API_ROOT_URL}/branches/create`,
      method: "POST",
      headers: getAuthHeaders(),
      data: data,
    };
  },

  // REPORTS

  getAllReports: (page, limit, searchQuery, status, year) => {
    const queryParams = [];
    if (searchQuery) {
      queryParams.push(`q=${searchQuery}`);
    }
    if (status) {
      queryParams.push(`status=${status}`);
    }
    if (year) {
      queryParams.push(`year=${year}`);
    }

    const url = `${API_ROOT_URL}/reports?page=${page}&limit=${limit}${
      queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
    }`;
    return {
      url,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getReportsByRetailerCode: (
    retailerCode,
    page,
    limit,
    searchQuery,
    status,
    years
  ) => {
    const queryParams = [];

    if (searchQuery) {
      queryParams.push(`q=${searchQuery}`);
    }
    if (status) {
      queryParams.push(`orderStatus=${status}`);
    }
    if (years) {
      queryParams.push(`year=${years}`);
    }

    const url = `${API_ROOT_URL}/reports/retailer/${retailerCode}?page=${page}&limit=${limit}${
      queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
    }`;

    return {
      url,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getReportsByAgentCode: (
    agentCode,
    page,
    limit,
    searchQuery,
    status,
    years
  ) => {
    const queryParams = [];

    if (searchQuery) {
      queryParams.push(`q=${searchQuery}`);
    }
    if (status) {
      queryParams.push(`orderStatus=${status}`);
    }
    if (years) {
      queryParams.push(`year=${years}`);
    }

    const url = `${API_ROOT_URL}/reports/agent/${agentCode}?page=${page}&limit=${limit}${
      queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
    }`;

    return {
      url,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getReportById: (id) => {
    return {
      url: `${API_ROOT_URL}/reports/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getReportYears: () => {
    return {
      url: `${API_ROOT_URL}/reports/years`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  createNewReport: (newReportData) => {
    return {
      url: `${API_ROOT_URL}/reports`,
      method: "POST",
      headers: getAuthHeaders(),
      data: newReportData,
    };
  },
  updateReport: (id, status) => {
    return {
      url: `${API_ROOT_URL}/reports/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: { status },
    };
  },

  // ORDERS

  getAllOrders: (
    page,
    limit,
    searchQuery,
    status,
    specialOrdersFilter,
    date
  ) => {
    const queryParams = [];

    if (searchQuery) {
      queryParams.push(`q=${searchQuery}`);
    }
    if (status) {
      queryParams.push(`orderStatus=${status}`);
    }

    if (specialOrdersFilter) {
      queryParams.push(`specialOrders=${specialOrdersFilter}`);
    }
    if (date?.startDate && date?.endDate) {
      queryParams.push(`dateFrom=${date.startDate}`);
      queryParams.push(`dateTo=${date.endDate}`);
    }

    const url = `${API_ROOT_URL}/orders?page=${page}&limit=${limit}${
      queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
    }`;

    return {
      url,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getOrdersByRetailerCode: (
    retailerCode,
    page,
    limit,
    searchQuery,
    status,
    specialOrdersFilter,
    date
  ) => {
    const queryParams = [];

    if (searchQuery) {
      queryParams.push(`q=${searchQuery}`);
    }
    if (status) {
      queryParams.push(`orderStatus=${status}`);
    }

    if (specialOrdersFilter) {
      queryParams.push(`specialOrders=${specialOrdersFilter}`);
    }
    if (date?.startDate && date?.endDate) {
      queryParams.push(`dateFrom=${date.startDate}`);
      queryParams.push(`dateTo=${date.endDate}`);
    }

    const url = `${API_ROOT_URL}/orders/retailer/${retailerCode}?page=${page}&limit=${limit}${
      queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
    }`;

    return {
      url,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getOrdersByAgentCode: (
    agentCode,
    page,
    limit,
    searchQuery,
    status,
    specialOrdersFilter,
    date
  ) => {
    const queryParams = [];

    if (searchQuery) {
      queryParams.push(`q=${searchQuery}`);
    }
    if (status) {
      queryParams.push(`orderStatus=${status}`);
    }

    if (specialOrdersFilter) {
      queryParams.push(`specialOrders=${specialOrdersFilter}`);
    }
    if (date?.startDate && date?.endDate) {
      queryParams.push(`dateFrom=${date.startDate}`);
      queryParams.push(`dateTo=${date.endDate}`);
    }

    const url = `${API_ROOT_URL}/orders/agent/${agentCode}?page=${page}&limit=${limit}${
      queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
    }`;

    return {
      url,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getOrderById: (id) => {
    return {
      url: `${API_ROOT_URL}/orders/detail/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  updateOrderStatus: (id, status) => {
    return {
      url: `${API_ROOT_URL}/orders/status/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: { status },
    };
  },

  rejectOrder: (id) => {
    return {
      url: `${API_ROOT_URL}/orders/reject/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
    };
  },

  duplicateOrderById: (id) => {
    return {
      url: `${API_ROOT_URL}/orders/duplicate/${id}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  updateOrderById: (updateData, hubspotId) => {
    return {
      url: `${API_ROOT_URL}/orders/${updateData.orderCode}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: { order: updateData, hubspotId },
    };
  },
  deleteOrderById: (id) => {
    return {
      url: `${API_ROOT_URL}/orders/${id}`,
      method: "DELETE",
      headers: getAuthHeaders(),
    };
  },
  getOrderYears: () => {
    return {
      url: `${API_ROOT_URL}/orders/years`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  importOrders: (file) => {
    return {
      url: `${API_ROOT_URL}/orders/import`,
      method: "POST",
      headers: getAuthHeaders(),
      data: file,
    };
  },

  createNewOrder: (newOrderData, hubspotId) => {
    return {
      url: `${API_ROOT_URL}/orders`,
      method: "POST",
      headers: getAuthHeaders(),
      data: { order: newOrderData, hubspotId },
    };
  },

  // GRAPH DASHBOARD DATA
  getGraphOrderData: () => {
    return {
      url: `${API_ROOT_URL}/orders/graph/data`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  // CAROUSEL DASHBOARD DATA
  getCarouselOrderData: () => {
    return {
      url: `${API_ROOT_URL}/orders/carousel/data`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getGraphOrderDataByRetailerId: (retailerId) => {
    return {
      url: `${API_ROOT_URL}/orders/graph/data/retailer/${retailerId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getCarouselOrderDataByRetailerId: (retailerId) => {
    return {
      url: `${API_ROOT_URL}/orders/carousel/data/retailer/${retailerId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getGraphOrderDataByAgentId: (agentId) => {
    return {
      url: `${API_ROOT_URL}/orders/graph/data/agent/${agentId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getCarouselOrderDataByAgentId: (agentId) => {
    return {
      url: `${API_ROOT_URL}/orders/carousel/data/agent/${agentId}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // PRICELISTS

  getPricelistByUserCode: (userCode) => {
    return {
      url: `${API_ROOT_URL}/pricelists/${userCode}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // COMPONENTS

  getComponentsByUserCode: (userCode) => {
    return {
      url: `${API_ROOT_URL}/components/${userCode}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // PRODUCTS

  getAllProducts: (
    page,
    searchQuery,
    selectedCollection,
    selectedSource,
    selectedTipology
  ) => {
    const query = searchQuery ? `&q=${searchQuery}` : "";
    const collection = selectedCollection
      ? `&collection=${selectedCollection}`
      : "";
    const source = selectedSource ? `&source=${selectedSource}` : "";
    const tipology = selectedTipology ? `&tipology=${selectedTipology}` : "";

    return {
      url:
        searchQuery || selectedCollection || selectedSource || selectedTipology
          ? `${API_ROOT_URL}/products?page=${page}${query}${collection}${source}${tipology}`
          : `${API_ROOT_URL}/products?page=${page}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getProductById: (id) => {
    return {
      url: `${API_ROOT_URL}/products/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  createProduct: (productData) => {
    return {
      url: `${API_ROOT_URL}/products`,
      method: "POST",
      headers: getAuthHeaders(),
      data: productData,
    };
  },
  updateProduct: (id, updateData) => {
    return {
      url: `${API_ROOT_URL}/products/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data: updateData,
    };
  },
  deleteProduct: (id) => {
    return {
      url: `${API_ROOT_URL}/products/${id}`,
      method: "DELETE",
      headers: getAuthHeaders(),
    };
  },

  // REFERENTS

  getAllReferents: () => {
    return {
      url: `${API_ROOT_URL}/referents`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  createNewReferent: (referentData) => {
    return {
      url: `${API_ROOT_URL}/referents`,
      method: "POST",
      headers: getAuthHeaders(),
      data: referentData,
    };
  },

  // COLLECTIONS

  getAllCollections: () => {
    return {
      url: `${API_ROOT_URL}/collections`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // SOURCES

  getAllSources: () => {
    return {
      url: `${API_ROOT_URL}/sources`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // TIPOLOGIES PROJECTS

  getAllProjectsTipologies: () => {
    return {
      url: `${API_ROOT_URL}/tipologies`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // TIPOLOGIES PRODUCTS

  getAllProductsTipologies: () => {
    return {
      url: `${API_ROOT_URL}/products_typologies`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // PERIODS

  getAllPeriods: () => {
    return {
      url: `${API_ROOT_URL}/periods`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // CATALOGUES
  getAllCatalogues: () => {
    return {
      url: `${API_ROOT_URL}/catalogues`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // VIDEOS
  getAllVideos: () => {
    return {
      url: `${API_ROOT_URL}/videos`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // CONTENTS
  getAllContents: () => {
    return {
      url: `${API_ROOT_URL}/contents`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  createNewContent: (contentData) => {
    return {
      url: `${API_ROOT_URL}/contents`,
      method: "POST",
      headers: getAuthHeaders(),
      data: contentData,
    };
  },

  // PROJECTS

  getAllProjects: (
    searchQuery,
    selectedCollection,
    selectedTipology,
    selectedPeriods
  ) => {
    const query = searchQuery ? `&q=${searchQuery}` : "";
    const collection = selectedCollection
      ? `&collection=${selectedCollection}`
      : "";
    const tipology = selectedTipology ? `&tipology=${selectedTipology}` : "";
    const periods = selectedPeriods ? `&periods=${selectedPeriods}` : "";

    return {
      url:
        searchQuery || selectedCollection || selectedTipology || selectedPeriods
          ? `${API_ROOT_URL}/projects?${query}${collection}${tipology}${periods}`
          : `${API_ROOT_URL}/projects`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
};

export default requests;
