import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  getAllloading: false,
  loadingMore: false,
  error: false,
  data: [],
  totalPage: 0,
  totalProjects: 0,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProjects.pending, (state, action) => {
        state.loading = true;
        state.getAllloading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllloading = false;
        state.data = action.payload.projects;
        state.totalPage = action.payload.totalPage;
        state.totalProjects = action.payload.totalProjects;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.loading = false;
        state.getAllloading = false;
        state.error = action.payload;
      })
      .addCase(addMoreProjects.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreProjects.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = [...state.data, ...action.payload.projects];
        state.totalPage = action.payload.totalPage;
        state.totalProjects = action.payload.totalProjects;
      })
      .addCase(addMoreProjects.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      });
  },
});

export const getAllProjects = createAsyncThunk(
  "reportsSlice/getAllProjects",
  async (data) => {
    try {
      const { url, method, headers } = api.getAllProjects(
        data.searchQuery,
        data.selectedCollection,
        data.selectedTipology,
        data.selectedPeriods
      );
      const projects = await axios({
        url,
        method,
        headers,
      });
      return projects.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const addMoreProjects = createAsyncThunk(
  "reportsSlice/addMoreProjects",
  async (data) => {
    try {
      const { url, method, headers } = api.getAllProjects(
        data.currentPage,
        data.searchQuery,
        data.selectedCollection,
        data.selectedTipology
      );
      const projects = await axios({
        url,
        method,
        headers,
      });
      return projects.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = projectsSlice;
export default projectsSlice.reducer;
