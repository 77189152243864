import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
import axios from "axios";

const initialState = {
  loading: false,
  error: false,
  data: [],
  totalPage: 0,
  totalVideos: 0,
};

const videosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllVideos.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        /*         state.totalPage = action.payload.totalPage;
        state.totalVideos = action.payload.totalCatalogues; */
      })
      .addCase(getAllVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllVideos = createAsyncThunk(
  "videosSlice/getAllVideos",
  async (data) => {
    try {
      const { url, method, headers } = api.getAllVideos();
      /*       data.currentPage,
        data.searchQuery,
        data.selectedCollection,
        data.selectedSource,
        data.selectedTipology */

      const videos = await axios({
        url,
        method,
        headers,
      });
      return videos.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = videosSlice;
export default videosSlice.reducer;
