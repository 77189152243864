import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  loadingMore: false,
  error: false,
  data: [],
  totalPage: 0,
  totalProducts: 0,
  singleProduct: {},
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.products;
        state.totalPage = action.payload.totalPage;
        state.totalProducts = action.payload.totalProducts;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      })
      .addCase(addMoreProducts.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreProducts.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = [...state.data, ...action.payload.products];
        state.totalPage = action.payload.totalPage;
        state.totalProducts = action.payload.totalProducts;
      })
      .addCase(addMoreProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProductById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleProduct = action.payload;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [...state.data, action.payload];
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.singleProduct = action.payload;
        state.data = state.data.map((product) => {
          if (product.id === action.payload.id) {
            return action.payload;
          }
          return product;
        });
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data?.filter(
          (product) => product.productCode !== action.payload.productCode
        );
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllProducts = createAsyncThunk(
  "reportsSlice/getAllProducts",
  async (data) => {
    try {
      const { url, method, headers } = api.getAllProducts(
        data.currentPage,
        data.searchQuery,
        data.selectedCollection,
        data.selectedSource,
        data.selectedTipology
      );
      const products = await axios({
        url,
        method,
        headers,
      });
      return products.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const addMoreProducts = createAsyncThunk(
  "reportsSlice/addMoreProducts",
  async (data) => {
    try {
      const { url, method, headers } = api.getAllProducts(
        data.currentPage,
        data.searchQuery,
        data.selectedCollection,
        data.selectedSource,
        data.selectedTipology
      );
      const products = await axios({
        url,
        method,
        headers,
      });
      return products.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getProductById = createAsyncThunk(
  "reportsSlice/getProductById",
  async (id) => {
    try {
      const { url, method, headers } = api.getProductById(id);
      const product = await axios({
        url,
        method,
        headers,
      });
      return product.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const createProduct = createAsyncThunk(
  "reportsSlice/createProduct",
  async (productData) => {
    try {
      const { url, method, headers, data } = api.createProduct(productData);
      const product = await axios({
        url,
        method,
        headers,
        data,
      });
      return product.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "reportsSlice/updateProduct",
  async (updateData) => {
    try {
      const { url, method, headers, data } = api.updateProduct(
        updateData.productCode,
        updateData
      );
      const product = await axios({
        url,
        method,
        headers,
        data,
      });
      return product.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "reportsSlice/deleteProduct",
  async (id) => {
    try {
      const { url, method, headers } = api.deleteProduct(id);
      const product = await axios({
        url,
        method,
        headers,
      });
      return product.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = productsSlice;
export default productsSlice.reducer;
