import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
import axios from "axios";

const initialState = {
  loading: false,
  error: false,
  data: [],
  cataloguesData: [],
  totalPage: 0,
  totalCatalogues: 0,
};

const cataloguesSlice = createSlice({
  name: "catalogues",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllCatalogues.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllCatalogues.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.totalPage = action.payload.totalPage;
        state.totalCatalogues = action.payload.totalCatalogues;
      })
      .addCase(getAllCatalogues.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllCatalogues = createAsyncThunk(
  "cataloguesSlice/getAllCatalogues",
  async () => {
    try {
      const { url, method, headers } = api.getAllCatalogues();
      const catalogues = await axios({
        url,
        method,
        headers,
      });
      return catalogues.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = cataloguesSlice;
export default cataloguesSlice.reducer;
