import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const periodsSlice = createSlice({
  name: "periods",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllPeriods.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllPeriods.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllPeriods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllPeriods = createAsyncThunk(
  "periodsSlice/getAllPeriods",
  async () => {
    try {
      const { url, method, headers } = api.getAllPeriods();
      const referents = await axios({
        url,
        method,
        headers,
      });
      return referents.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = periodsSlice;
export default periodsSlice.reducer;
