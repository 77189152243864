import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardOrder: [],
  widgetOrder: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    initDashboard(state, action) {
      return { ...state, dashboardOrder: action.payload };
    },
    deleteDashboard(state, action) {
      return {
        ...state,
        dashboardOrder: [],
      };
    },
    addDashboard(state, action) {
      return {
        ...state,
        dashboardOrder: [...state.dashboardOrder, action.payload],
      };
    },
    removeDashboard(state, action) {
      return {
        ...state,
        dashboardOrder: state.dashboardOrder.filter(
          (item) => item !== action.payload
        ),
      };
    },
    initWidget(state, action) {
      return { ...state, widgetOrder: action.payload };
    },
    deleteWidget(state, action) {
      return {
        ...state,
        widgetOrder: [],
      };
    },
    addWidget(state, action) {
      return {
        ...state,
        widgetOrder: [...state.widgetOrder, action.payload],
      };
    },
    removeWidget(state, action) {
      return {
        ...state,
        widgetOrder: state.widgetOrder.filter(
          (item) => item !== action.payload
        ),
      };
    },
  },
});

export const {
  addDashboard,
  removeDashboard,
  addWidget,
  removeWidget,
  initDashboard,
  initWidget,
  deleteWidget,
  deleteDashboard,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
