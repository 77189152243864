import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const sourcesSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllSources.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSources.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllSources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllSources = createAsyncThunk(
  "sourcesSlice/getAllSources",
  async () => {
    try {
      const { url, method, headers } = api.getAllSources();
      const referents = await axios({
        url,
        method,
        headers,
      });
      return referents.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = sourcesSlice;
export default sourcesSlice.reducer;
