import React from "react";
import styled, { keyframes } from "styled-components";

const load = keyframes`
 0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  &:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }

  border-radius: 50%;
  width: ${(props) => props.size || "5em"};
  height: ${(props) => props.size || "5em"};
  margin: ${(props) => props.marginSpinner || "60px auto"};
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(0, 0, 0, 0.2);
  border-right: 0.5em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: ${(props) =>
    props.color ? `0.5em solid ${props.color}` : "0.5em solid #0b1e2d"};
  color: ${(props) => props.color || "#fff"};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${load} 1s infinite linear;
  animation: ${load} 1s infinite linear;
`;

export const Spinner = (props) => {
  return <StyledSpinner {...props} />;
};

const StyledBtnSpinner = styled.div`
  &:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
  border-radius: 50%;
  width: 2em;
  height: 2em;
  /* margin: 60px auto; */
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(255, 255, 255, 0.2);
  border-right: 0.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
  border-left: 0.5em solid #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${load} 1s infinite linear;
  animation: ${load} 1s infinite linear;
`;

export const BtnSpinner = () => {
  return <StyledBtnSpinner />;
};
