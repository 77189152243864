// import { combineReducers } from "redux";

import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./features/userSlice";
import accountReducer from "./features/accountSlice";
import orderReducer from "./features/ordersSlice";
import reportReducer from "./features/reportsSilce";
import dashboardReducer from "./features/dashboardSlice";
import productReducer from "./features/productsSlice";
import referentReducer from "./features/referentSlice";
import collectionReducer from "./features/collectionsSlice";
import sourceReducer from "./features/sourcesSlice";
import tipologyReducer from "./features/tipologiesSlice";
import periodsReducer from "./features/periodsSlice";
import pricelistReducer from "./features/pricelistsSlice";
import branchReducer from "./features/branchesSlice";
import componentReducer from "./features/componentsSlice";
import orderDataReducer from "./features/orderDataSlice";
import cataloguesReducer from "./features/cataloguesSlice";
import projectsReducer from "./features/projectsSlice";
import videosReducer from "./features/videosSlice";
import contentsReducer from "./features/contentsSlice";
// export default combineReducers({
//   account,
// });

export const store = configureStore({
  reducer: {
    users: userReducer,
    account: accountReducer,
    orders: orderReducer,
    reports: reportReducer,
    dashboard: dashboardReducer,
    products: productReducer,
    referents: referentReducer,
    collections: collectionReducer,
    sources: sourceReducer,
    tipologies: tipologyReducer,
    periods: periodsReducer,
    pricelists: pricelistReducer,
    branches: branchReducer,
    components: componentReducer,
    orderData: orderDataReducer,
    catalogues: cataloguesReducer,
    projects: projectsReducer,
    videos: videosReducer,
    contents: contentsReducer,
  },
  // devTools: true,
  // devTools: {
  //   name: "Redux DevTools",
  //   realtime: true,
  //   trace: true,
  //   traceLimit: 25,
  //   actionSanitizer: (action) => {
  //     return {
  //       ...action,
  //       payload: action.payload.toString().substring(0, 100),
  //     };
  //   },
  // },
});
