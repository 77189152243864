import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  createLoading: false,
  loadingMore: false,
  error: false,
  data: [],
  years: [],
  singleReport: {},
  reportCreated: false,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetSuccess: (state, action) => {
      state.reportCreated = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllReports.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getReportsByRetailerCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReportsByRetailerCode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getReportsByRetailerCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getReportsByAgentCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReportsByAgentCode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getReportsByAgentCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getReportById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReportById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleReport = action.payload;
      })
      .addCase(getReportById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createNewReport.pending, (state, action) => {
        state.loading = true;
        state.createLoading = true;
      })
      .addCase(createNewReport.fulfilled, (state, action) => {
        state.loading = false;
        state.createLoading = false;
        state.reportCreated = true;
      })
      .addCase(createNewReport.rejected, (state, action) => {
        state.loading = false;
        state.createLoading = false;
        state.reportCreated = false;
        state.error = action.payload;
      })
      .addCase(updateReport.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        state.loading = false;
        state.singleReport = action.payload;
        state.data = {
          ...state.data,
          reports: state.data.reports.filter(
            (report) => report.id !== action.payload.id
          ),
        };
      })
      .addCase(updateReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addMoreReports.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreReports.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = {
          ...state.data,
          reports: [...state.data.reports, ...action.payload.reports],
          totalCount: action.payload.totalCount,
          page: action.payload.page,
        };
      })
      .addCase(addMoreReports.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      })
      .addCase(addMoreReportsByRetailerCode.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreReportsByRetailerCode.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = {
          ...state.data,
          reports: [...state.data.reports, ...action.payload.reports],
          totalCount: action.payload.totalCount,
          page: action.payload.page,
        };
      })
      .addCase(addMoreReportsByRetailerCode.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      })
      .addCase(addMoreReportsByAgentCode.pending, (state, action) => {
        state.loadingMore = true;
      })
      .addCase(addMoreReportsByAgentCode.fulfilled, (state, action) => {
        state.loadingMore = false;
        state.data = {
          ...state.data,
          reports: [...state.data.reports, ...action.payload.reports],
          totalCount: action.payload.totalCount,
          page: action.payload.page,
        };
      })
      .addCase(addMoreReportsByAgentCode.rejected, (state, action) => {
        state.loadingMore = false;
        state.error = action.payload;
      })
      .addCase(getReportYears.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReportYears.fulfilled, (state, action) => {
        state.loading = false;
        state.years = action.payload;
      })
      .addCase(getReportYears.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.years = [];
      });
  },
});

export const getAllReports = createAsyncThunk(
  "reportsSlice/getAllReports",
  async ({ currentPage, pageSize, q, orderStatus, year }) => {
    try {
      const { url, method, headers } = api.getAllReports(
        currentPage,
        pageSize,
        q,
        orderStatus,
        year
      );
      const reports = await axios({
        url,
        method,
        headers,
      });
      return reports.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getReportsByRetailerCode = createAsyncThunk(
  "reportsSlice/getReportsByRetailerCode",
  async ({ retailerCode, currentPage, pageSize, q, orderStatus, year }) => {
    try {
      const { url, method, headers } = api.getReportsByRetailerCode(
        retailerCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        year
      );

      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getReportsByAgentCode = createAsyncThunk(
  "reportsSlice/getReportsByAgentCode",
  async ({ agentCode, currentPage, pageSize, q, orderStatus, year }) => {
    try {
      const { url, method, headers } = api.getReportsByAgentCode(
        agentCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        year
      );

      const orders = await axios({
        url,
        method,
        headers,
      });
      return orders.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getReportById = createAsyncThunk(
  "reportsSlice/getReportById",
  async (id) => {
    try {
      const { url, method, headers } = api.getReportById(id);
      const report = await axios({
        url,
        method,
        headers,
      });
      return report.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const createNewReport = createAsyncThunk(
  "reportsSlice/createNewReport",
  async (newReportData) => {
    try {
      const { url, method, headers, data } = api.createNewReport(newReportData);
      const newReport = await axios({
        url,
        method,
        headers,
        data,
      });
      return newReport.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const updateReport = createAsyncThunk(
  "reportsSlice/updateReport",
  async (updateData) => {
    try {
      const { url, method, headers, data } = api.updateReport(
        updateData.id,
        updateData.status
      );
      const report = await axios({
        url,
        method,
        headers,
        data,
      });
      return report.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getReportYears = createAsyncThunk(
  "reportsSlice/getReportYears",
  async () => {
    try {
      const { url, method, headers } = api.getReportYears();
      const order = await axios({
        url,
        method,
        headers,
      });
      return order.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const addMoreReports = createAsyncThunk(
  "reportsSlice/addMoreReports",
  async ({
    currentPage,
    pageSize,
    q,
    orderStatus,
    year,
    specialOrdersFilter,
  }) => {
    try {
      const { url, method, headers } = api.getAllReports(
        currentPage,
        pageSize,
        q,
        orderStatus,
        year,
        specialOrdersFilter
      );
      const reports = await axios({
        url,
        method,
        headers,
      });
      return reports.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const addMoreReportsByRetailerCode = createAsyncThunk(
  "reportsSlice/addMoreReportsByRetailerCode",
  async ({
    retailerCode,
    currentPage,
    pageSize,
    q,
    orderStatus,
    year,
    specialOrdersFilter,
  }) => {
    try {
      const { url, method, headers } = api.getReportsByRetailerCode(
        retailerCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        year,
        specialOrdersFilter
      );

      const reports = await axios({
        url,
        method,
        headers,
      });
      return reports.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const addMoreReportsByAgentCode = createAsyncThunk(
  "reportsSlice/addMoreReportsByAgentCode",
  async ({
    agentCode,
    currentPage,
    pageSize,
    q,
    orderStatus,
    year,
    specialOrdersFilter,
  }) => {
    try {
      const { url, method, headers } = api.getReportsByAgentCode(
        agentCode,
        currentPage,
        pageSize,
        q,
        orderStatus,
        year,
        specialOrdersFilter
      );

      const reports = await axios({
        url,
        method,
        headers,
      });
      return reports.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { resetSuccess } = reportsSlice.actions;
export const { actions } = reportsSlice;
export default reportsSlice.reducer;
