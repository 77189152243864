import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  error: false,
  data: [],
  newReferent: null,
};

const referentsSlice = createSlice({
  name: "referents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllReferents.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllReferents.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllReferents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(createNewReferent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createNewReferent.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data
          ? [...state.data, action.payload]
          : [action.payload];
        state.newReferent = action.payload;
      })
      .addCase(createNewReferent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllReferents = createAsyncThunk(
  "referentsSlice/getAllReferents",
  async () => {
    try {
      const { url, method, headers } = api.getAllReferents();
      const referents = await axios({
        url,
        method,
        headers,
      });
      return referents.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const createNewReferent = createAsyncThunk(
  "referentsSlice/createNewReferent",
  async (newReferentData) => {
    try {
      const { url, method, headers, data } =
        api.createNewReferent(newReferentData);
      const newReferent = await axios({
        url,
        method,
        headers,
        data,
      });
      return newReferent.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = referentsSlice;
export default referentsSlice.reducer;
