import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  error: false,
  branchCreated: false,
  data: [],
};

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    resetBranchCreated: (state, action) => {
      state.branchCreated = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBranchesByUserId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBranchesByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getBranchesByUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createNewBranch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createNewBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.branchCreated = true;
        state.data = [...state.data, action.payload];
      })
      .addCase(createNewBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getBranchesByUserId = createAsyncThunk(
  "branchesSlice/getBranchesByUserId",
  async (userCode) => {
    try {
      const { url, method, headers } = api.getBranchesByUserId(userCode);
      const branches = await axios({
        url,
        method,
        headers,
      });
      return branches.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const createNewBranch = createAsyncThunk(
  "branchesSlice/createNewBranch",
  async (branchData) => {
    try {
      const { url, method, headers, data } = api.createNewBranch(branchData);
      const branches = await axios({
        url,
        method,
        headers,
        data,
      });
      return branches.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const { actions } = branchesSlice;
export const { resetBranchCreated } = branchesSlice.actions;
export default branchesSlice.reducer;
