import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
const axios = require("axios");

const initialState = {
  loading: false,
  carouselLoading: false,
  error: false,
  data: [],
  carouselData: [],
};

const orderDataSlice = createSlice({
  name: "orderData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGraphOrderData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGraphOrderData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getGraphOrderData.rejected, (state, action) => {
        state.error = action.payload;
        console.log("error", state.error);
        state.loading = false;
      })
      .addCase(getCarouselOrderData.pending, (state, action) => {
        state.carouselLoading = true;
      })
      .addCase(getCarouselOrderData.fulfilled, (state, action) => {
        state.carouselLoading = false;
        state.carouselData = action.payload;
      })
      .addCase(getCarouselOrderData.rejected, (state, action) => {
        state.error = action.payload;
        console.log("error", state.error);
        state.carouselLoading = false;
        state.carouselData = [];
      })
      .addCase(getGraphOrderDataByRetailerId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGraphOrderDataByRetailerId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getGraphOrderDataByRetailerId.rejected, (state, action) => {
        state.error = action.payload;
        console.log("error", state.error);
        state.loading = false;
      })
      .addCase(getCarouselOrderDataByRetailerId.pending, (state, action) => {
        state.carouselLoading = true;
      })
      .addCase(getCarouselOrderDataByRetailerId.fulfilled, (state, action) => {
        state.carouselLoading = false;
        state.carouselData = action.payload;
      })
      .addCase(getCarouselOrderDataByRetailerId.rejected, (state, action) => {
        state.error = action.payload;
        console.log("error", state.error);
        state.carouselLoading = false;
        state.carouselData = [];
      })
      .addCase(getGraphOrderDataByAgentId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGraphOrderDataByAgentId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getGraphOrderDataByAgentId.rejected, (state, action) => {
        state.error = action.payload;
        console.log("error", state.error);
        state.loading = false;
      })
      .addCase(getCarouselOrderDataByAgentId.pending, (state, action) => {
        state.carouselLoading = true;
      })
      .addCase(getCarouselOrderDataByAgentId.fulfilled, (state, action) => {
        state.carouselLoading = false;
        state.carouselData = action.payload;
      })
      .addCase(getCarouselOrderDataByAgentId.rejected, (state, action) => {
        state.error = action.payload;
        console.log("error", state.error);
        state.carouselLoading = false;
        state.carouselData = [];
      });
  },
});

export const getGraphOrderData = createAsyncThunk(
  "orderDataSlice/getGraphOrderData",
  async () => {
    try {
      const { url, method, headers } = api.getGraphOrderData();
      const orderData = await axios({
        url,
        method,
        headers,
      });
      return orderData.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getCarouselOrderData = createAsyncThunk(
  "orderDataSlice/getCarouselOrderData",
  async () => {
    try {
      const { url, method, headers } = api.getCarouselOrderData();
      const orderData = await axios({
        url,
        method,
        headers,
      });
      return orderData.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const getGraphOrderDataByRetailerId = createAsyncThunk(
  "orderDataSlice/getGraphOrderDataByRetailerId",
  async (retailerId) => {
    try {
      const { url, method, headers } =
        api.getGraphOrderDataByRetailerId(retailerId);
      const orderData = await axios({
        url,
        method,
        headers,
      });
      return orderData.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getCarouselOrderDataByRetailerId = createAsyncThunk(
  "orderDataSlice/getCarouselOrderDataByRetailerId",
  async (retailerId) => {
    try {
      const { url, method, headers } =
        api.getCarouselOrderDataByRetailerId(retailerId);
      const orderData = await axios({
        url,
        method,
        headers,
      });
      return orderData.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const getGraphOrderDataByAgentId = createAsyncThunk(
  "orderDataSlice/getGraphOrderDataByAgentId",
  async (agentId) => {
    try {
      const { url, method, headers } = api.getGraphOrderDataByAgentId(agentId);
      const orderData = await axios({
        url,
        method,
        headers,
      });
      return orderData.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getCarouselOrderDataByAgentId = createAsyncThunk(
  "orderDataSlice/getCarouselOrderDataByAgentId",
  async (agentId) => {
    try {
      const { url, method, headers } =
        api.getCarouselOrderDataByAgentId(agentId);
      const orderData = await axios({
        url,
        method,
        headers,
      });
      return orderData.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = orderDataSlice;
export default orderDataSlice.reducer;
