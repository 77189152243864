import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
import axios from "axios";

const initialState = {
  loading: false,
  error: false,
  data: [],
  activatedUser: false,
  singleUser: {},
  retailerData: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetActivatedUser(state, action) {
      state.activatedUser = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPaginatedUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPaginatedUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPaginatedUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUsersByAgentCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUsersByAgentCode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUsersByAgentCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUserByUserCode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserByUserCode.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerData = action.payload;
      })
      .addCase(getUserByUserCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {
          ...state.data,
          users: state.data.users.map((user) => {
            if (user.id === action.payload.id) {
              return action.payload;
            }
            return user;
          }),
        };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(activateUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {
          ...state.data,
          users: state.data.users.map((user) => {
            if (user.id === action.payload.id) {
              return action.payload;
            }
            return user;
          }),
        };
        state.activatedUser = true;
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getAllUsers = createAsyncThunk(
  "userSlice/getAllUsers",
  async () => {
    try {
      const { url, method, headers } = api.getAllUsers();
      const users = await axios({
        url,
        method,
        headers,
      });
      return users.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getPaginatedUsers = createAsyncThunk(
  "userSlice/getPaginatedUsers",
  async ({ currentPage, pageSize, q }) => {
    try {
      const { url, method, headers } = api.getPaginatedUsers(
        currentPage,
        pageSize,
        q
      );

      const users = await axios({
        url,
        method,
        headers,
      });
      return users.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const getUsersByAgentCode = createAsyncThunk(
  "userSlice/getUsersByAgentCode",
  async (agentCode) => {
    try {
      const { url, method, headers } = api.getUsersByAgentCode(agentCode);
      const users = await axios({
        url,
        method,
        headers,
      });
      return users.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const getUserByUserCode = createAsyncThunk(
  "userSlice/getUserByUserCode",
  async (userCode) => {
    try {
      const { url, method, headers } = api.getUserByUserCode(userCode);
      const users = await axios({
        url,
        method,
        headers,
      });
      return users.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const updateUser = createAsyncThunk(
  "userSlice/updateUser",
  async ({ userCode, updateData }) => {
    try {
      const { url, method, headers, data } = api.updateUser(
        userCode,
        updateData
      );

      const user = await axios({
        url,
        method,
        headers,
        data,
      });

      return user.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const activateUser = createAsyncThunk(
  "userSlice/activateUser",
  async ({ userCode, updateData }) => {
    try {
      const { url, method, headers, data } = api.activateUser(
        userCode,
        updateData
      );

      const user = await axios({
        url,
        method,
        headers,
        data,
      });

      return user.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { resetActivatedUser } = usersSlice.actions;
export const { actions } = usersSlice;
export default usersSlice.reducer;
